.monument-block {
   .menu-entry {
      .submenu {
         .submenu-entry {
            .img-holder {
               background-size: contain;

               &.schloss {
                  &.heidelberg {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_17_Heidelberg_Aussen_4C_300_mod_aufgehellt_foto-ssg-guenther-bayerl.webp");
                  }

                  &.schwetzingen {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_37_schwetzingen_aussen_am_0946_2009_4c_300.webp");
                  }

                  &.ludwigsburg {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_24_Ludwigsburg_RSL_Aussen_LMZ972187_2004_DL_foto-ssg_ralf-cohen.webp");
                  }

                  &.weikersheim {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/weikersheim.webp");
                  }

                  &.mannheim {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/26_mannheim_innen_treppenhaus_foto-ssg-marco-vedana_200x140.webp");
                  }

                  &.rastatt {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_33_rastatt-rsl_aussen_foto-AERO-SUEDWEST-_monika-schlangen.webp");
                  }

                  &.bruchsal {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_13_bruchsal_p7_luft_4538_2010_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.tettnang {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_41_Tettnang_Aussen_009_foto-ssg-guenther-bayerl.webp");
                  }

                  &.solitude {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_39_solitude_aussen_lmz973151_2008_4c_300_mod_foto-ssg-joachim-feist.webp");
                  }

                  &.ellwangen {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_15_ellwangen_detail_schlossmuseum-ellwangen_nb_decke_4c_300_mod_hg_foto-ssg-urheber-unbekannt.webp");
                  }

                  &.favorite.rastatt {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_34_rastatt_sf_aussen_lmz316139_1993_4c_300_mod_03_foto-ssg-arnim-weischer.webp");
                  }

                  &.mergentheim {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/65_mergentheim-rsl_aussen_foto-ssg-guenter-bayerl-151_200x140.webp");
                  }

                  &.urach {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_04_Bad_Urach_Innen_LMZ973128_2000_4C_300_mod_foto-ssg-sven-grenzemann.webp");
                  }

                  &.favorite.ludwigsburg {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/25_ludiwgsburg_sf_aussen_park_foto_SSG_guenther_bayerl_442_200x140.webp");
                  }

                  &.kirchheim {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_21_Kirchheim_Innen_Schloss_Enfilade_ohne_Tuerrahmen_4C_300_mod_foto-ssg-julia-haseloff.webp");
                  }

                  &.meersburg {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/29_Meersburg_innen_ssg_Guenther_Bayerl_1800_200x140.webp");
                  }

                  &.salem {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_35_salem_luft_6894_2005_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.bebenhausen {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/csm_10_Bebenhausen_K_Innen_RH_DSC3570_2010_Kreuzgang__Nordfluegel_foto-ssg-rose-hajdu.webp");
                  }

                  &.alle {
                     background-image: url("../assets/megamenu-thumbnails/schloesser/schloesser.svg");
                  }
               }

               &.garten {
                  &.karlsruhe {
                     background-image: url("../assets/megamenu-thumbnails/gaerten/karlsruhe.webp");
                  }
                  &.badenweiler {
                     background-image: url("../assets/megamenu-thumbnails/gaerten/68_kurpark-badenweiler_aussen_DSC03048_oto-badenweiler-thermen-und-touristik-gmbh_300.webp");
                  }
                 
                  &.alle {
                     background-image: url("../assets/megamenu-thumbnails/gaerten/gaerten.svg");
                  }
               }

               &.burg {
                  &.hohentwiel {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_38_singen_luft_0117_2010_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.roetteln {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_23_loerrach-haagen_aussen_lmz495578_4c_300_mod_foto-ssg-arnim-weischer.webp");
                  }

                  &.hohenneuffen {
                     background-image: url("../assets/megamenu-thumbnails/burgen/hohenneuffen.webp");
                  }

                  &.emmendingen {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_16_emmendingen_luft_20100429_4947_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.hohenbaden {
                     background-image: url("../assets/megamenu-thumbnails/burgen/hohenbaden.webp");
                  }

                  &.waescherschloss {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_44_waeschenbeuren_aussen_mauer-und-fachwerk_4c_300_mod_foto-ssg-ov-ludwigsburg.webp");
                  }

                  &.dilsburg {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_30_neckargemuend_aussen_lmz318104_1993_4c_300_mod_foto-ssg-arnim-weischer.webp");
                  }

                  &.badenweiler {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_09_badenweiler_br_aussen_8399_2010_foto-ssg-achim-mende.webp");
                  }

                  &.yburg {
                     background-image: url("../assets/megamenu-thumbnails/burgen/yburg.webp");
                  }

                  &.alt-eberstein {
                     background-image: url("../assets/megamenu-thumbnails/burgen/alt-eberstein.webp");
                  }

                  &.hohenstauffen {
                     background-image: url("../assets/megamenu-thumbnails/burgen/csm_60_goeppingen_luft_2012_2009_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.alle {
                     background-image: url("../assets/megamenu-thumbnails/burgen/burgen.svg");
                  }
               }

               &.kloster {
                  &.maulbronn {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_27_Maulbronn_Innen_LMZ008659_1995_Brunnen_foto-ssg-dieter-jaeger.webp");
                  }

                  &.wiblingen {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_42_ulm-wiblingen_innen_lmz970457_4c_300_mod_foto-ssg-steffen-hauswirth.webp");
                  }

                  &.lorch {
                     background-image: url("../assets/megamenu-thumbnails/kloester/lorch.webp");
                  }

                  &.schussenried {
                     background-image: url("../assets/megamenu-thumbnails/kloester/03_badschussenried_innen_bibliothekssaal_62A878_foto-ssg_200x140.webp");
                  }

                  &.grosscomburg {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_61_Grosscomburg_Aussen_LMZ328084_RGB_mod_foto-ssg-arnim-weischer.webp");
                  }

                  &.hirsau {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_14_calw-hirsau_aussen_lmz311996_nb_marienkapelle_4c_300_mod_foto-ssg-andrea-rachele.webp");
                  }

                  &.alpirsbach {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_01_Alpirsbach_Besucher_AM_2829_2010_4C_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.schoental {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_36_schoental_aussen_lmz973149_2007_4c_300_mod1_s_foto-ssg-juergen-besserer.webp");
                  }

                  &.ochsenhausen {
                     background-image: url("../assets/megamenu-thumbnails/kloester/csm_31_Ochsenhausen_Luft_0156_2010_4C_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.heiligkreuztal {
                     background-image: url("../assets/megamenu-thumbnails/kloester/altheim_detail_lmz008646_nb_kirche_ostfenster_foto-lmz-dieter-jaeger_200x140n.webp");
                  }

                  &.alle {
                     background-image: url("../assets/megamenu-thumbnails/kloester/kloester.svg");
                  }
               }

               &.kleinod {
                  &.wuerttemberg {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/csm_40_stuttgart-rotenberg_luft_vh6b0094_2009_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.heuneburg {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/66_herbertingen-heuneburg_aussen_luft_anbau_043_foto-ssg-guenther-bayerl_200x140.webp");
                  }

                  &.badenweiler {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/csm_08_badenweiler_rb_aussen_8279_2010_4c_300_mod_foto-ssg-achim-mende.webp");
                  }

                  &.meersburg {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/28_meersburg-fh_aussen_2725_foto-ssg-bernhard-wrobel_200x140.webp");
                  }

                  &.grabkapelle {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/19_Karlsruhe_GK_aussen_GB070_RGB_ssg_guenther_bayerl_1800_200x140.webp");
                  }

                  &.huefingen {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/csm_18_huefingen_innen_roemerbad_4804_nb_4c_300_mod_foto-ssg-roland-sigwart.webp");
                  }

                  &.domnick {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/63_nuertingen-sd_aussen_Seite20_foto-ssg-guenther-bayerl_200x140.webp");
                  }

                  &.alle {
                     background-image: url("../assets/megamenu-thumbnails/kleinode/kleinode.svg");
                  }
               }
            }
         }
      }
   }
}
